import {
  BankCustomFields,
  CrbServiceProvider,
  CreateDocumentsRequest,
  CustomRequirement,
  Dispensary,
  DocumentChecker,
  DocumentUpload,
  DueDiligenceDocument,
  DueDiligenceFulfillment,
  DueDiligenceStatus,
  LicenseDataMap,
  LicenseDocumentDataRequest,
  LicenseManagerData,
  LinkedDocId,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult,
  RequirementCategory,
  RequirementId,
  RequirementReference,
  TemplateResponse,
  TemplateResultResponse,
  User
} from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { StepperStep, StepProps } from 'ui';
import { OnboardingRepo } from './onboarding.repo';
import {
  hasDispensaryFulfilledAllAddtionalRequirements,
  hasDispensaryFulfilledAllDocumentRequirements
} from 'util/org.util';
import { GREEN_CHECK_ONBOARDING_BANK_ID } from '@gcv/shared';
import { FieldValues } from 'react-hook-form';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { AccountOwners } from './pages/account-owners';
import BusinessDetails from './pages/business-details';
import { OperationalDetails } from './pages/operational-details';
import { IdVerification } from './pages/id-verification';
import {
  getOnboardingRequirementsForCrb,
  isOnboardingDocumentRequirementFulfilled
} from 'util/due-diligence.util';
import { filterObjectMap, getObjectMap } from 'util/objectUtils';
import { ComplianceStore } from 'stores/ComplianceStore';
import { uploadOrgDocumentToS3, viewDocument } from 'util/s3.util';
import { Documentation } from './pages/documentation';
import { AdditionalInformation } from './pages/additional-info';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { track } from 'third-party-integrations/pendo';
import { UserStore } from 'stores/UserStore';

export type DocumentationCategoryFilters =
  | 'all'
  | RequirementCategory.Financial
  | RequirementCategory.Legal
  | RequirementCategory.License
  | RequirementCategory.Operational;
export type DocumentationStatusFilters = 'all' | 'fulfilled' | 'unfulfilled';

export interface OnboardingDocumentRequirementMap {
  [id: string]: OnboardingDocumentRequirement & RequirementReference;
}
interface InvitedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
export interface VM {
  dueDiligenceSteps: StepperStep[];
  redirectPath: string;
  logoLink: string;
  dispensary: Dispensary;
  dispenaryIsPlantTouching: boolean;
  provider: CrbServiceProvider;
  onboardingTemplate: TemplateResponse;
  user: User;
  invitedUsers: InvitedUser[];
  dispensaryStaff: User[];
  isLoading: boolean;
  isAutoSaving: boolean | null;
  navDrawerOpen: boolean;
  showOnboardingSuccessModal: boolean;
  accountOwnerModalOpen: boolean;
  onboardingSuccessModalOpen: boolean;
  isConnectedToPayqwick: boolean;
  requireAdditionalAccountOwners: boolean;
  singleAccountOwner: boolean;
  isLoadingAccountOwner: boolean;
  isLoadingBusinessDetails: boolean;
  isSavingBusinessDetails: boolean;
  isSavingOperationalDetails: boolean;
  posType: string;
  isSavingIdVerification: boolean;
  idFrontDocument: DocumentUpload | undefined;
  idBackDocument: DocumentUpload | undefined;
  isSavingIdDocs: boolean;
  isDeletingIdDocs: boolean;
  idType: string;
  licenseData: LicenseManagerData;
  licenseDataMap: LicenseDataMap;
  isLoadingDocumentation: boolean;
  isSavingDocumentation: boolean;
  allRequirements: OnboardingDocumentRequirementMap;
  templateResults: TemplateResultResponse;
  onboardingTemplateResult: TemplateResultResponse;
  allDocumentsMap: { [id: string]: DocumentUpload };
  categoryFilter: DocumentationCategoryFilters;
  statusFilter: DocumentationStatusFilters;
  filteredRequirements: string[];
  isLoadingDocumentChange: boolean;
  isLoadingDocumentUpload: boolean;
  isLoadingLicenseUpload: boolean;
  uploadingOnboardingTemplateDocuments: boolean;
  savingOnboardingTemplate: boolean;
  deletingOnboardingTemplateDocuments: boolean;
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
}

@injectable()
export class OnboardingPresenter {
  @inject(OnboardingRepo)
  public repo: OnboardingRepo;

  @inject(ComplianceStore)
  public complianceStore: ComplianceStore;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(UserStore)
  public userStore: UserStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    dueDiligenceSteps: [],
    redirectPath: '',
    logoLink: '',
    dispensary: {} as Dispensary,
    dispenaryIsPlantTouching: false,
    provider: {} as CrbServiceProvider,
    onboardingTemplate: {} as TemplateResponse,
    user: {} as User,
    invitedUsers: [],
    dispensaryStaff: [],
    isLoading: true,
    isAutoSaving: null,
    navDrawerOpen: false,
    showOnboardingSuccessModal: false,
    accountOwnerModalOpen: false,
    onboardingSuccessModalOpen: false,
    isConnectedToPayqwick: false,
    requireAdditionalAccountOwners: false,
    singleAccountOwner: true,
    isLoadingAccountOwner: false,
    isLoadingBusinessDetails: false,
    isSavingBusinessDetails: false,
    isSavingOperationalDetails: false,
    posType: '',
    isSavingIdVerification: false,
    idFrontDocument: undefined,
    idBackDocument: undefined,
    isSavingIdDocs: false,
    isDeletingIdDocs: false,
    idType: '',
    licenseData: {} as LicenseManagerData,
    licenseDataMap: {} as LicenseDataMap,
    isLoadingDocumentation: false,
    isSavingDocumentation: false,
    allRequirements: {},
    templateResults: {} as TemplateResultResponse,
    onboardingTemplateResult: {} as TemplateResultResponse,
    allDocumentsMap: {},
    categoryFilter: 'all',
    statusFilter: 'all',
    filteredRequirements: [],
    isLoadingDocumentChange: false,
    isLoadingDocumentUpload: false,
    isLoadingLicenseUpload: false,
    uploadingOnboardingTemplateDocuments: false,
    savingOnboardingTemplate: false,
    deletingOnboardingTemplateDocuments: false,
    sharedRootDocs: []
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  loadStore = action(async (templateId: string, templateResultId: string, bankId: string) => {
    await this.repo.loadOnboarding(templateId, templateResultId, bankId);
    this.viewModel.onboardingTemplateResult = this.repo.programmersModel.onboardingResult;
  });

  loadOnboardingTemplateAndResponse = action(
    async (templateId: string, templateResultId: string, bankId: string) => {
      this.updateViewModel({ isLoading: true });
      await this.loadStore(templateId, templateResultId, bankId);
      const activeCustomRequirements = this.getActiveRequirements(
        this.repo.programmersModel.onboardingTemplate.custom_requirements
      );
      const hasAdditionalInfo =
        activeCustomRequirements &&
        Object.keys(this.repo.programmersModel.onboardingTemplate?.custom_requirements).length > 0;
      const dueDiligenceComplete = DocumentChecker.getDispensaryDueDiligenceFulfillment(
        this.repo.programmersModel.dispensary
      );
      const dueDiligenceStatus = this.repo.programmersModel.onboardingResult.status;
      this.setSteps(
        dueDiligenceComplete,
        dueDiligenceStatus,
        bankId !== GREEN_CHECK_ONBOARDING_BANK_ID,
        hasAdditionalInfo,
        !!this.repo.programmersModel.user.identityVerified,
        this.repo.programmersModel.licenseDataMap[this.repo.programmersModel.dispensary.state]
          .employee_license_required,
        this.repo.programmersModel.onboardingTemplate,
        this.repo.programmersModel.onboardingResult
      );

      const isConnectedToPayqwick = this.repo.programmersModel.isConnectedToPayqwick;
      const provider = this.repo.programmersModel.provider;
      const posType = this.repo.programmersModel.dispensary.pos_name || '';
      const redirectPath = this.repo.programmersModel.dispensaryIsPlantTouching
        ? '/secure/crb/dashboard'
        : `/secure/crb/${this.repo.programmersModel.dispensary.id}/my-providers`;
      this.updateViewModel({
        isLoading: false,
        dispensary: this.repo.programmersModel.dispensary,
        dispenaryIsPlantTouching: this.repo.programmersModel.dispensaryIsPlantTouching,
        dispensaryStaff: this.repo.programmersModel.dispensaryStaff,
        provider: provider,
        onboardingTemplate: this.repo.programmersModel.onboardingTemplate,
        onboardingTemplateResult: this.repo.programmersModel.onboardingResult,
        isConnectedToPayqwick: isConnectedToPayqwick,
        user: this.repo.programmersModel.user,
        posType: posType,
        licenseDataMap: this.complianceStore.licenseDataMap,
        redirectPath: redirectPath,
        idType: this.repo.programmersModel.user.identification?.idType,
        sharedRootDocs: this.repo.programmersModel.sharedRootDocs
      });
      this.loadUserDocuments();
      this.loadAccountOwners();
      this.loadDocumentation();
    }
  );

  handleComplete = () => {
    console.log('on complete');
  };

  setOpenNavDrawer = (isOpen: boolean) => {
    this.updateViewModel({ navDrawerOpen: isOpen });
  };

  toggleNavDrawerOpen = () => {
    this.updateViewModel({ navDrawerOpen: !this.viewModel.navDrawerOpen });
  };

  setShowOnboardingSuccessModal = (isOpen: boolean) => {
    this.updateViewModel({ showOnboardingSuccessModal: isOpen });
  };

  trackSuccessModalClick = (bankId: string) => {
    const data = {
      provider_id: this.repo.crbBankStore.banks[bankId].id,
      provider_name: this.repo.crbBankStore.banks[bankId].orgName,
      crb_id: this.repo.dispensaryStore.currentDispensary.id,
      crb_name: this.repo.dispensaryStore.currentDispensary.name,
      user_id: this.userStore.user.id,
      user_name: this.userStore.user.firstName + ' ' + this.userStore.user.lastName
    };

    if (this.repo.dispensaryStore.currentDispensaryIsPlantTouching) {
      track('CRB | Onboarding | Application Success (PT)', data);
    } else {
      track('CRB | Onboarding | Application Success (NPT)', data);
    }
  };

  updateDispensaryStore = () => {
    this.repo.dispensaryStore.updateDispensary(this.viewModel.dispensary);
    this.viewModel.invitedUsers.forEach((staff) => {
      this.repo.dispensaryStore.updateDispensaryStaff(staff as User);
    });
    this.repo.userStore.updateUser(this.viewModel.user);
  };

  getActiveRequirements = (requirements: {
    [requirement_id: string]: CustomRequirement & RequirementReference;
  }) => {
    const activeRequirements: { [requirement_id: string]: CustomRequirement & RequirementReference } = {};
    // remove the archived requirements
    // though all the requirements are archived, archived requirements check is not done
    //  and Additional Information step is enabled
    if (requirements && Object.keys(requirements).length > 0) {
      Object.keys(requirements).forEach((req) => {
        if (!requirements[req].archived) {
          // when we delete a section on FI, an empty section is being added. This was to support
          // standalone fields which now is deprecated
          // below condition is to check the same, as this is enabling the Additional Information step in onboarding
          if (requirements[req].custom_section.label !== '') {
            activeRequirements[req] = requirements[req];
          }
        }
      });
    }
    return activeRequirements;
  };

  setSteps = action(
    (
      dueDiligenceComplete: {
        fulfillmentMap: DueDiligenceFulfillment;
        fulfilled: boolean;
      },
      dueDiligenceStatus: DueDiligenceStatus,
      isConnectedToBank: boolean,
      hasCustomFields: boolean,
      identityVerified: boolean,
      employeeLicensesRequired: boolean,
      onboardingTemplate: TemplateResponse,
      onboardingTemplateResult: TemplateResultResponse
    ) => {
      this.updateViewModel({ isLoading: true });
      const accountOwners: StepperStep = {
        label: 'Account Owners',
        id: 'account-owners',
        Content: (props: StepProps) => <AccountOwners {...props} />,
        complete: true
      };

      const businessDetails: StepperStep = {
        label: 'Business Details',
        id: 'business-details',
        Content: (props: StepProps) => <BusinessDetails {...props} />,
        complete: dueDiligenceComplete.fulfillmentMap.business_details.complete
      };

      const operationalDetails: StepperStep = {
        label: 'Operational Details',
        id: 'operational-details',
        Content: (props: StepProps) => <OperationalDetails {...props} />,
        complete: dueDiligenceComplete.fulfillmentMap.operational_details.complete
      };

      const documentationComplete = hasDispensaryFulfilledAllDocumentRequirements(
        onboardingTemplate,
        onboardingTemplateResult,
        employeeLicensesRequired
      );

      const documentation: StepperStep = {
        label: 'Documentation',
        id: 'documentation',
        Content: (props: StepProps) => <Documentation {...props} />,
        complete: documentationComplete
      };

      const idVerification: StepperStep = {
        label: 'ID Verification',
        id: 'id-verification',
        Content: (props: StepProps) => <IdVerification {...props} />,
        complete: identityVerified
      };

      const additionalInformation: StepperStep = {
        label: 'Additional Information',
        id: 'additional-information',
        Content: (props: StepProps) => <AdditionalInformation {...props} />,
        complete: hasDispensaryFulfilledAllAddtionalRequirements(
          this.repo.programmersModel.onboardingTemplate,
          this.repo.programmersModel.onboardingResult
        )
      };

      let dueDiligenceSteps = [
        accountOwners,
        businessDetails,
        operationalDetails,
        documentation,
        idVerification
      ];

      if (isConnectedToBank) {
        switch (dueDiligenceStatus) {
          case DueDiligenceStatus.GCV_PENDING:
          case DueDiligenceStatus.BANK_PENDING:
          case DueDiligenceStatus.BANK_IN_PROGRESS:
          case DueDiligenceStatus.GCV_IN_PROGRESS: {
            if (hasCustomFields) {
              dueDiligenceSteps = [...dueDiligenceSteps, additionalInformation];
            } else {
              dueDiligenceSteps = [...dueDiligenceSteps];
            }
            break;
          }
        }
      }
      this.updateViewModel({ dueDiligenceSteps: dueDiligenceSteps, isLoading: false });
    }
  );

  allStepsCompleted = action(() => {
    return this.viewModel.dueDiligenceSteps.every((step) => step.complete);
  });

  submitOnboardingTemplateResults = action(async () => {
    this.viewModel.isLoading = true;
    await this.repo.submitOnboardingTemplateResults();
    await this.repo.refreshDispensaryStoreDispensary();
    this.updateViewModel({ isLoading: false, showOnboardingSuccessModal: true });

    track('CRB | Onboarding | Submit Application', {
      provider_id: this.viewModel.provider.id,
      provider_name: this.viewModel.provider.orgName,
      crb_id: this.viewModel.dispensary.id,
      crb_name: this.viewModel.dispensary.name,
      user_id: this.userStore.user.id,
      user_name: this.userStore.user.firstName + ' ' + this.userStore.user.lastName
    });
  });

  // ----------------- account owners functionality -----------------
  loadAccountOwners = action(() => {
    if (this.viewModel.dispensaryStaff && this.viewModel.dispensary && this.viewModel.dispensary.id) {
      const accountOwnersList = this.viewModel.dispensary.groups.find(
        (group) => group.type === 'account_owner'
      );
      const accountOwners = accountOwnersList!.users.map((userId) => {
        const user = this.viewModel.dispensaryStaff.find((s) => s.id === userId);
        return {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email
        } as InvitedUser;
      });

      if (accountOwners.length > 1) {
        this.viewModel.singleAccountOwner = false;
      }
      this.viewModel.invitedUsers = accountOwners;
    }
  });

  saveAccountOwners = action((onNext: () => void) => {
    if (this.viewModel.singleAccountOwner === true || this.viewModel.invitedUsers.length) {
      this.viewModel.requireAdditionalAccountOwners = false;
      setTimeout(() => {
        try {
          onNext();
          this.snackbarStore.showSnackbar(
            SnackbarSeverity.Success,
            'Account owners have been set successfully.'
          );
        } catch (e) {
          this.snackbarStore.showSnackbar(
            SnackbarSeverity.Error,
            'There was an issue updating account owners.'
          );
        }
        this.viewModel.isLoadingAccountOwner = false;
      }, 300);
    } else {
      this.viewModel.requireAdditionalAccountOwners = true;
      this.viewModel.isLoadingAccountOwner = false;
    }
  });

  inviteUser = action(async (data: FieldValues) => {
    this.setAccountOwnerModal(false);
    this.updateViewModel({ isLoadingAccountOwner: true });
    const response = await this.repo.inviteUser(data);
    if (response) {
      this.viewModel.invitedUsers = [...this.viewModel.invitedUsers, response.user];
    }
    this.updateViewModel({ isLoadingAccountOwner: false });
  });

  setAccountOwnerModal = action((open: boolean) => {
    this.updateViewModel({ accountOwnerModalOpen: open });
  });

  setSingleAccountOwner = action((isSingle: boolean) => {
    this.updateViewModel({ singleAccountOwner: isSingle });
  });

  // ----------------- business details functionality -----------------
  autoSaveBusinessDetails = action(async (data: FieldValues) => {
    this.viewModel.isAutoSaving = true;
    const dispensary = await this.repo.autoSaveBusinessDetails(data);
    if (dispensary) {
      Object.keys(dispensary).forEach((key: string) => {
        if (this.viewModel.dispensary[key as keyof Dispensary] !== dispensary[key as keyof Dispensary]) {
          (this.viewModel.dispensary as any)[key] = dispensary[key as keyof Dispensary];
        }
      });
    }
    this.viewModel.isAutoSaving = false;
  });

  submitBusinessDetails = action(async (data: FieldValues, onNext: () => void, isLastStep: boolean) => {
    this.viewModel.isSavingBusinessDetails = true;
    await this.repo.updateBusinessDetails(data, onNext);
    this.refreshSteps();
    this.viewModel.isSavingBusinessDetails = false;
    if (isLastStep && this.allStepsCompleted()) {
      this.submitOnboardingTemplateResults();
    }
  });

  // ----------------- operational details functionality -----------------
  autoSaveOperationalDetails = action(async (data: FieldValues) => {
    this.viewModel.isAutoSaving = true;
    const dispensary = await this.repo.autoSaveOperationalDetails(data);
    this.viewModel.dispensary.businessHours = dispensary.businessHours;
    this.viewModel.dispensary.business_type = dispensary.business_type;
    this.viewModel.dispensary.ftEmployees = dispensary.ftEmployees;
    this.viewModel.dispensary.monthlyCustomers = dispensary.monthlyCustomers;
    this.viewModel.dispensary.monthlySales = dispensary.monthlySales;
    this.viewModel.dispensary.pos_name = dispensary.pos_name;
    this.viewModel.dispensary.pos_name_other = dispensary.pos_name_other;
    this.viewModel.dispensary.ptEmployees = dispensary.ptEmployees;
    this.viewModel.isAutoSaving = false;
  });

  submitOperationalDetails = action(async (data: FieldValues, onNext: () => void, isLastStep: boolean) => {
    this.viewModel.isSavingOperationalDetails = true;
    await this.repo.updateOperationalDetails(data, onNext);
    this.refreshSteps();
    this.viewModel.isSavingOperationalDetails = false;
    if (isLastStep && this.allStepsCompleted()) {
      this.submitOnboardingTemplateResults();
    }
  });

  setPosType = action((posType: string) => {
    this.viewModel.posType = posType;
  });

  // ----------------- Identity Verification functionality -----------------
  loadUserDocuments = action(async () => {
    const docs = await this.repo.getUserDocs(
      this.viewModel.user.identification?.idFront?.document_id,
      this.viewModel.user.identification?.idBack?.document_id
    );
    this.updateViewModel({
      idFrontDocument: docs?.frontDoc,
      idBackDocument: docs?.backDoc
    });
  });

  updateUserIdentification = action(async (data: FieldValues, onNext?: () => void) => {
    const user = await this.repo.updateUserIdentification(
      data,
      this.viewModel.dispensary.iana_timezone,
      this.viewModel.user,
      onNext
    );
    if (user) {
      this.viewModel.user = user;
    }
  });

  autoSaveUserIdentification = action(async (data: FieldValues) => {
    this.viewModel.isAutoSaving = true;
    const user = await this.repo.autoSaveUserIdentification(
      data,
      this.viewModel.dispensary.iana_timezone,
      this.viewModel.user
    );
    if (user) {
      this.viewModel.user = user;
    }
    this.viewModel.isAutoSaving = false;
  });

  uploadNewId = async (file: File): Promise<DocumentUpload> => {
    return this.repo.uploadNewId(file, this.viewModel.user);
  };

  updateIdentityDocs = action(async (newIdFront?: File, newIdBack?: File) => {
    this.viewModel.isSavingIdDocs = true;
    const docs = await this.repo.updateIdentityDocs(newIdFront, newIdBack, this.viewModel.user);
    this.viewModel.idFrontDocument = docs?.frontDoc ? docs.frontDoc : this.viewModel.idFrontDocument;
    this.viewModel.idBackDocument = docs?.backDoc ? docs.backDoc : this.viewModel.idBackDocument;
    if (docs?.updatedUser) {
      this.viewModel.user = docs?.updatedUser;
    }
    this.viewModel.isSavingIdDocs = false;
  });

  deleteFrontIdDoc = action(async () => {
    this.viewModel.isDeletingIdDocs = true;
    const updatedIdentification = { ...this.viewModel.user.identification };
    delete updatedIdentification.idFront;
    const user = await this.repo.deleteIdDoc(updatedIdentification, this.viewModel.user);
    if (user) {
      this.viewModel.user = user;
    }
    this.viewModel.idFrontDocument = undefined;
    this.viewModel.isDeletingIdDocs = false;
  });

  deleteBackIdDoc = action(async () => {
    this.viewModel.isDeletingIdDocs = true;
    const updatedIdentification = { ...this.viewModel.user.identification };
    delete updatedIdentification.idBack;
    const user = await this.repo.deleteIdDoc(updatedIdentification, this.viewModel.user);
    if (user) {
      this.viewModel.user = user;
    }
    this.viewModel.idBackDocument = undefined;
    this.viewModel.isDeletingIdDocs = false;
  });

  submitUserIdentification = action(async (data: FieldValues, onNext: () => void, isLastStep: boolean) => {
    if (!this.viewModel.user.identification?.idFront || !this.viewModel.user.identification?.idBack) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'Identification files upload required');
      return;
    }
    this.viewModel.isSavingIdVerification = true;
    await this.updateUserIdentification(data, onNext);
    this.refreshSteps();
    if (isLastStep && this.allStepsCompleted() && !this.viewModel.isLoading) {
      await this.submitOnboardingTemplateResults();
    }
    this.viewModel.isSavingIdVerification = false;
  });

  setIdType = action((idType: string) => {
    this.viewModel.idType = idType;
  });

  // ----------------- Documentation functionality -----------------
  loadDocumentation = action(async () => {
    this.viewModel.isLoadingDocumentation = true;
    this.viewModel.licenseData = this.complianceStore.licenseDataMap[this.viewModel.dispensary.state];
    //get a full mapping of requirements that we need
    this.viewModel.allRequirements = this.getAllRequirementData();
    //create a dereferenced copy of the template results to prevent annoying re-rendering issue on OnboardingStepper
    this.viewModel.templateResults = JSON.parse(JSON.stringify(this.viewModel.onboardingTemplateResult));
    this.filterRequirements();

    //get all documents
    const documents = await this.repo.getAllDocuments();
    this.viewModel.allDocumentsMap = getObjectMap(documents, 'id');
    this.viewModel.isLoadingDocumentation = false;
  });

  getAllDocuments = action(async () => {
    const documents = await this.repo.getAllDocuments();
    const documentsMap = getObjectMap(documents, 'id');
    this.viewModel.allDocumentsMap = documentsMap;
  });

  getAllRequirementData = () => {
    const isEmployeeLicenseRequired = this.viewModel.licenseData.employee_license_required;
    const activeBankRequirements = getOnboardingRequirementsForCrb(
      this.viewModel.onboardingTemplate,
      isEmployeeLicenseRequired
    );

    return activeBankRequirements;
  };

  filterRequirements = action((updatedRequirements?: OnboardingDocumentRequirementMap) => {
    const reqsToFilers = updatedRequirements ?? this.viewModel.allRequirements;
    const filterFunction = (requirement: OnboardingDocumentRequirement): boolean => {
      const result = this.viewModel.templateResults.requirements_results.find(
        (r) => r.requirement_id === requirement.requirement_id
      );
      return (
        this.viewModel.statusFilter === 'all' ||
        (this.viewModel.statusFilter === 'fulfilled' && isOnboardingDocumentRequirementFulfilled(result)) ||
        (this.viewModel.statusFilter === 'unfulfilled' && !isOnboardingDocumentRequirementFulfilled(result))
      );
    };
    const filteredRequirements = filterObjectMap(reqsToFilers, filterFunction);
    this.viewModel.filteredRequirements = Object.keys(filteredRequirements);
  });

  hasDocumentRequirement = () => {
    const filteredRequirements = filterObjectMap(this.viewModel.allRequirements, (req) => {
      return this.viewModel.filteredRequirements.includes(req.requirement_id);
    });
    let hasDocumentRequirements = false;
    Object.values(filteredRequirements).map((requirement) => {
      const documentRequirement = this.viewModel.templateResults.requirements_results.find(
        (r) => requirement && r.requirement_id === requirement.requirement_id
      )?.documents;

      if (documentRequirement && Object.keys(documentRequirement).length > 0) {
        hasDocumentRequirements = true;
      }
    });
    return hasDocumentRequirements;
  };

  submitDocumentation = (onNext: () => void) => {
    this.viewModel.onboardingTemplateResult = JSON.parse(JSON.stringify(this.viewModel.templateResults));
    if (this.viewModel.dispensary && this.checkIfDocumentationSectionComplete()) {
      onNext();
      this.refreshSteps();
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'Documents have been updated successfully.');
    } else {
      this.viewModel.statusFilter = 'unfulfilled';
      this.filterRequirements();
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'You have some unfulfilled requirements. Add them below in order to continue.'
      );
    }
  };

  refreshSteps = () => {
    const activeCustomRequirements = this.getActiveRequirements(
      this.repo.programmersModel.onboardingTemplate.custom_requirements
    );
    const hasAdditionalInfo =
      activeCustomRequirements &&
      Object.keys(this.repo.programmersModel.onboardingTemplate?.custom_requirements).length > 0;
    const dueDiligenceComplete = DocumentChecker.getDispensaryDueDiligenceFulfillment(
      this.repo.programmersModel.dispensary
    );
    const dueDiligenceStatus = this.repo.programmersModel.onboardingResult.status;

    this.setSteps(
      dueDiligenceComplete,
      dueDiligenceStatus,
      this.viewModel.provider.id !== GREEN_CHECK_ONBOARDING_BANK_ID,
      hasAdditionalInfo,
      !!this.viewModel.user.identityVerified,
      this.viewModel.licenseDataMap[this.viewModel.dispensary.state].employee_license_required,
      this.viewModel.onboardingTemplate,
      this.viewModel.templateResults
    );
  };

  checkIfDocumentationSectionComplete = () => {
    const isEmployeeLicenseRequired = this.viewModel.licenseData.employee_license_required;
    const complete = hasDispensaryFulfilledAllDocumentRequirements(
      this.viewModel.onboardingTemplate,
      this.viewModel.templateResults,
      isEmployeeLicenseRequired
    );
    return complete;
  };

  setStatusFilter = action(async (status: DocumentationStatusFilters) => {
    this.viewModel.statusFilter = status;
    this.filterRequirements();
  });

  downloadRequirementAttachments = action(async (requirement: OnboardingDocumentRequirement) => {
    await this.repo.downloadRequirementAttachment(requirement);
  });

  updateDoNotHaveCommentOnRequirement = action(
    async (requirementId: RequirementId, doNotHave: boolean, doNotHaveComment: string) => {
      const templateResultResponse = await this.repo.setOnboardingDocumentRequirementDoNotHave(
        this.viewModel.dispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        requirementId,
        doNotHave,
        doNotHaveComment
      );
      this.updateRequirementResult(templateResultResponse);
    }
  );

  updateRequirementResult = action((requirementResult: OnboardingDocumentRequirementResult) => {
    const existingRequirement = this.viewModel.templateResults.requirements_results.find(
      (r) => r.requirement_id === requirementResult.requirement_id
    );
    if (existingRequirement) {
      const index = this.viewModel.templateResults.requirements_results.indexOf(existingRequirement);
      this.viewModel.templateResults.requirements_results.splice(index, 1, requirementResult);
      this.viewModel.onboardingTemplateResult = this.viewModel.templateResults;
    } else {
      this.viewModel.templateResults.requirements_results.push(requirementResult);
    }
    this.filterRequirements(this.viewModel.allRequirements);
  });

  viewAttachment = action(async (documentId: string) => {
    await this.repo.getAtttachment(this.viewModel.templateResults.bank_id, documentId);
  });

  removeDocumentFromRequirement = action(
    async (requirment: OnboardingDocumentRequirement, documentId: string) => {
      this.viewModel.isLoadingDocumentChange = true;
      const templateResultResponse = await this.repo.removeDocumentFromRequirement(
        requirment,
        documentId,
        this.viewModel.dispensary.id,
        this.viewModel.onboardingTemplate.template_id
      );
      this.updateRequirementResult(templateResultResponse);
      this.viewModel.isLoadingDocumentChange = false;
    }
  );

  addDocsToRequirement = action(
    async (
      documentsRequest: CreateDocumentsRequest,
      documentUpload: DocumentUpload,
      requirement: OnboardingDocumentRequirement,
      documentId: string
    ) => {
      this.viewModel.isLoadingDocumentUpload = true;
      if (
        documentsRequest.documents.length > 0 &&
        documentUpload &&
        documentsRequest.documents[0].s3_key !== documentUpload.s3_key
      ) {
        await this.removeDocumentFromRequirement(requirement, documentId);
        const templateResultResponse = await this.repo.addDocumentToRequirement(
          requirement,
          this.viewModel.dispensary.id,
          this.viewModel.onboardingTemplate.template_id,
          documentsRequest
        );
        this.updateRequirementResult(templateResultResponse);
        this.getAllDocuments();
      } else {
        const reqResult = await this.repo.updateLicenseDocument(
          requirement,
          documentId,
          documentsRequest.license_data ?? ({} as LicenseDocumentDataRequest),
          this.viewModel.onboardingTemplate.template_id
        );
        this.updateRequirementResult(reqResult);
      }
      this.viewModel.isLoadingDocumentUpload = false;
    }
  );

  addLicenseDocument = action(
    async (requirement: OnboardingDocumentRequirement, documentsRequest: CreateDocumentsRequest) => {
      this.viewModel.isLoadingLicenseUpload = true;
      const templateResultResponse = await this.repo.addDocumentToRequirement(
        requirement,
        this.viewModel.dispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        documentsRequest
      );
      this.updateRequirementResult(templateResultResponse);
      this.getAllDocuments();
      this.viewModel.isLoadingLicenseUpload = false;
    }
  );

  uploadFilesToRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      files: File[],
      sharedDocs?: DocumentUpload[],
      licenseData?: LicenseDocumentDataRequest
    ) => {
      this.viewModel.isLoadingDocumentUpload = true;
      const promises = files.map(async (file) => {
        const s3Key = await uploadOrgDocumentToS3(file, this.viewModel.dispensary.id);
        return { filename: file.name, s3_key: s3Key, req_name: requirement.name, shared: true };
      });

      const uploadedDocs = await Promise.all(promises);

      const sharedDocsRequest: {
        filename: string;
        s3_key: string;
        req_name: string;
        linked_doc_id: LinkedDocId;
      }[] = [];
      if (sharedDocs) {
        sharedDocs.map((doc) => {
          sharedDocsRequest.push({
            filename: doc.file_name,
            req_name: requirement.name,
            s3_key: doc.s3_key,
            linked_doc_id: `${doc.org_id}_${doc.id}`
          });
        });
      }

      const newDocuments: CreateDocumentsRequest = {
        documents: [...uploadedDocs, ...sharedDocsRequest],
        license_data: licenseData
      };

      const templateResultResponse = await this.repo.addOnboardingDocumentRequirementDocuments(
        this.viewModel.dispensary.id,
        this.viewModel.onboardingTemplate.template_id,
        requirement.requirement_id,
        newDocuments
      );
      this.updateRequirementResult(templateResultResponse);
      this.getAllDocuments();
      const reloadedSharedRootDocs = await this.repo.getSharedDcouments(this.viewModel.dispensary.id);
      this.viewModel.sharedRootDocs = reloadedSharedRootDocs;
      this.viewModel.isLoadingDocumentUpload = false;
    }
  );

  updateLicenseDocument = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      data: LicenseDocumentDataRequest
    ) => {
      this.viewModel.isLoadingDocumentChange = true;
      const reqResult = await this.repo.updateLicenseDocument(
        requirement,
        documentId,
        data,
        this.viewModel.onboardingTemplate.template_id
      );
      this.updateRequirementResult(reqResult);
      this.viewModel.isLoadingDocumentChange = false;
    }
  );

  // ----------------- Additional Information functionality -----------------

  getDefaultValues = () => {
    if (this.viewModel.onboardingTemplateResult.custom_requirement_result === undefined) return {};
    const updatedValues = {} as FieldValues;
    for (
      let index = 0;
      index < this.viewModel.onboardingTemplateResult.custom_requirement_result.length;
      index++
    ) {
      const customResult = this.viewModel.onboardingTemplateResult.custom_requirement_result[index];
      if (
        customResult.custom_fields &&
        customResult.custom_fields.responses &&
        Object.keys(customResult.custom_fields.responses).length > 0
      ) {
        Object.entries(customResult.custom_fields.responses).forEach((entry) => {
          updatedValues[entry[0]] = entry[1];
        });
      }
    }

    return updatedValues;
  };

  onNewFilesChange = action(async (requirementId: string, fieldId: string, files: File[]) => {
    const promises = [];
    const docs: DocumentUpload[] = [];

    // uploads file
    const fileUpload = async (file: File) => {
      const doc = await this.uploadOnboardingTemplateDocument(requirementId, fieldId, file);
      if (doc) docs.push(doc);
    };

    for (const file of files) {
      promises.push(fileUpload(file));
    }
    await Promise.all(promises);

    const ids = docs.map((doc) => {
      this.viewModel.allDocumentsMap[doc.id] = doc;
      return doc.id;
    });
    return ids;
  });

  submitAdditionalInformation = action(
    async (
      data: FieldValues,
      isLastStep: boolean,
      onNext: () => void,
      templateId: string,
      templateResultId: string,
      bankId: string
    ) => {
      await this.saveAndCompleteOnboardingTemplate(data, templateId, templateResultId, bankId);
      onNext();

      if (isLastStep && this.allStepsCompleted() && !this.viewModel.isLoading) {
        await this.submitOnboardingTemplateResults();
      }
    }
  );

  openDocument = async (docId: string, file_name: string) => {
    const blob = await viewDocument({ orgId: this.viewModel.dispensary.id, userType: 'dispensary' }, docId);

    if (blob) {
      openFileInNewWindow(blob, getMimeType(file_name), file_name);
    }
  };

  onSubmitError = () => {
    this.snackbarStore.showErrorSnackbarMessage(
      'Please fill out all required fields and try submitting again.'
    );
  };

  // leaving this for now but updatedTemplate is not used anywhere and the set steps don't need to be set with this
  // below lines were part of submitOnboardingTemplateResults function,
  // because of UI requirement (GS-10424), the template results needs to be updated
  // after user responds to the success modal and then redirect him
  // issue: getting bad-access route as the template results are updated but user is not redirected.
  // updateTemplateResultResponse = action(() => {
  //   const dueDiligenceComplete = DocumentChecker.getDispensaryDueDiligenceFulfillment(
  //     this.viewModel.dispensary
  //   );
  //   const dueDiligenceStatus = this.viewModel.onboardingTemplateResult.status;
  //   const hasAdditionalInfo =
  //     this.viewModel.onboardingTemplate.custom_requirements &&
  //     Object.keys(this.viewModel.onboardingTemplate?.custom_requirements).length > 0;
  //   this.setSteps(
  //     dueDiligenceComplete,
  //     dueDiligenceStatus,
  //     this.viewModel.onboardingTemplate.bank_id !== GREEN_CHECK_ONBOARDING_BANK_ID,
  //     hasAdditionalInfo,
  //     !!this.viewModel.user.identityVerified,
  //     this.viewModel.licenseDataMap[this.viewModel.dispensary.state].employee_license_required,
  //     this.viewModel.onboardingTemplate,
  //     this.viewModel.onboardingTemplateResult
  //   );
  //   this.viewModel.updatedTemplate = {} as TemplateResultResponse;
  // });

  uploadOnboardingTemplateDocument = action(async (requirementId: string, fieldId: string, file: File) => {
    this.viewModel.uploadingOnboardingTemplateDocuments = true;
    const document = await this.repo.uploadOnboardingTemplateDocument(requirementId, fieldId, file);
    this.viewModel.uploadingOnboardingTemplateDocuments = false;
    return document;
  });

  saveAndCompleteOnboardingTemplate = action(
    async (
      customFieldValues: Record<string, any>,
      templateId: string,
      templateResultId: string,
      bankId: string
    ) => {
      this.viewModel.savingOnboardingTemplate = true;
      await this.repo.saveAndCompleteOnboardingTemplate(customFieldValues);
      await this.loadStore(templateId, templateResultId, bankId);
      this.refreshSteps();
      this.viewModel.savingOnboardingTemplate = false;
    }
  );

  autoSaveAdditionalInfo = action(async (customFieldValues: Record<string, any>, sectionId: string) => {
    this.viewModel.isAutoSaving = true;
    await this.repo.autoSaveAdditionalInfo(customFieldValues, sectionId);
    this.viewModel.onboardingTemplateResult = this.repo.programmersModel.onboardingResult;
    this.viewModel.isAutoSaving = false;
  });

  deleteOnboardingTemplateDocument = action(
    async (requirementId: string, fieldId: string, file: DocumentUpload) => {
      this.viewModel.deletingOnboardingTemplateDocuments = true;
      await this.repo.deleteOnboardingTemplateDocument(requirementId, fieldId, file);
      this.viewModel.deletingOnboardingTemplateDocuments = false;
    }
  );
}
