import { BankOverview, IANATimezones } from '@gcv/shared';
import { BanksApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { AppViewStateStore } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';

export interface PM {
  bankOverview: null | BankOverview;
  loadingBankOverview: boolean;
  timezone: IANATimezones;
  loadingDataError: boolean;
  chartUrl?: string;
}

@injectable()
export class FiDashboardRepo {
  @inject(BanksApi) private banksApi: BanksApi;
  @inject(AppViewStateStore) public appViewStore: AppViewStateStore;
  @inject(FiBankStore) private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    bankOverview: null,
    loadingBankOverview: true,
    timezone: IANATimezones.America_NewYork,
    loadingDataError: false,
    chartUrl: ''
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = async () => {
    this.updateProgrammersModel({
      loadingDataError: false,
      loadingBankOverview: true
    });

    try {
      let overview, chartUrl;
      if (this.bankStore.bank.sigma_config?.enabled) {
        chartUrl = await this.fetchSigmaUrl();
      } else {
        overview = await this.fetchBankOverview();
      }

      this.updateProgrammersModel({
        bankOverview: overview,
        loadingBankOverview: false,
        timezone: this.bankStore.bank.iana_timezone,
        chartUrl: chartUrl?.url || ''
      });
    } catch (e) {
      this.updateProgrammersModel({
        loadingDataError: true,
        loadingBankOverview: false,
        timezone: this.bankStore.bank.iana_timezone
      });
    }
  };

  fetchSigmaUrl = async () => {
    try {
      return await this.banksApi.getBankSigmaUrl(this.bankStore.bank.id);
    } catch (e) {
      throw new Error('There was an issue loading the embeded chart. Contact support for additional help');
    }
  };

  fetchBankOverview = async () => {
    try {
      if (
        !this.appViewStore.dashboardTimePeriod.dateRange.start ||
        !this.appViewStore.dashboardTimePeriod.dateRange.end
      ) {
        return null;
      }
      return await this.banksApi.getBankOverview(
        this.bankStore.bank.id,
        this.appViewStore.dashboardTimePeriod.dateRange.start,
        this.appViewStore.dashboardTimePeriod.dateRange.end
      );
    } catch (e) {
      // All back-end errors are now instances of ApiError. We have the option to catch these
      // errors here at the repo level, on a case-by-case basis, if interrogating the error and
      // re-wrapping it as a custom error makes sense.
      //
      // This might make sense if we are reusing a repo for more than one presenter. Otherwise, as
      // in this case, we'd just send the ApiError all the way up to the presenter. (We're catching
      // and rethrowing in this example, just to make room for these comments.)
      throw e;
    }
  };
}
